import { useEffect, useState } from 'react'
import Turnstile from "react-turnstile";
import { useLocalization } from '~/hooks/localize';
export function TurnstileWidget({ action, onVerify }: { action: string; onVerify?: (e: any) => void }) {
  const { language } = useLocalization()
  const [isClient, setIsClient] = useState(false)
  useEffect(() => {
    setIsClient(true)
  }, [])

  return (<div className="w-full">
    {
      isClient && (
        <Turnstile
          fixedSize={true}
          sitekey="0x4AAAAAAAku7pPkl_2UJjQA"
          responseField={true}
          responseFieldName="cfTurnstileResponse"
          className='mb-4 turnstile'
          onVerify={(e) => onVerify && onVerify(e)}
          size='flexible'
          action={action}
          language={language}
        />
      )
    }

  </div>)
}
